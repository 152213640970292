<template>
  <div>
    <div v-if="this.$route.name === 'MultipleForm'">
      <MultipleForm />
    </div>
    <router-view :key="$route.fullPath"/>
  </div>
</template>
<script>
import MultipleForm from "@/views/companyApp/multiple/MultipleForm.vue";

export default {
  name: 'MultipleFormParent',
  components: {
    MultipleForm
  }
}
</script>
